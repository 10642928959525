import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgReduceAdmin from "../images/reduce-admin.jpg"
import imgMobileTicket from "../images/mobile-ticket-for-school-transport.jpg"
import imgSaveTime from "../images/save-time-money-using-shuttleid.jpg"
import imgBusPassRenewal from "../images/school-bus-pass-renewal.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"
import Nav from "../components/nav"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="10 ways to save admin on your home-to-school services" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">10 ways to save admin on your home-to-school services</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">10 ways to save admin on your home-to-school services</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>

                    <p>With margins getting tighter, it makes sense to occasionally take a step back and reassess which areas of the business can be made more efficient.</p>
                    <p>Growing your school services over the years makes it all too easy to forget that the process you originally had in place to manage a small number of passengers, may no longer be suitable for a larger customer base and may even be costing you time and money.</p>
                    <p>Here are ten ways you can crank up the efficiency on your home-to-school operation in a way that will delight customers and save you time:</p>
                    <p><img src={imgReduceAdmin} alt="" /></p>
                  </div>
                  <div className='article-inner'>

                    <h2>1. Ditch the postal applications forms</h2>
                    <p>Parents having to print and post application forms to you is time consuming for the parent and even more time consuming for you. Once you have the dozen or hundreds of forms, you will have to process all the information, which involves manually inputting data into your own systems.</p>
                    <p>If the parents use an online form, the information is readily available to you, saving you a big data-entry task whilst also speeding up the process as a whole.</p>

                    <h2>2. Photo uploads</h2>
                    <p>If you use photo IDs, you may be asking parents to post you photographs. Again, this will involve you having to scan, crop and upload these photos, which is a very manual process. If a parent can upload photos straight to you, within a secure system which ensures the image is the right size and quality, you will save hours of frustration.</p>
                    <p>Even photos sent by email, whilst being faster than "snail mail", still makes it a manual one-to-one human process and requires your team to handle these requests when then could be doing other work deemed more valuable. There is also the small matter that <strong>email is not secure</strong> enough to send passenger photos as an attachment.</p>

                    <h2>3. Sell tickets online</h2>
                    <img src={imgSaveTime} alt="Save time by selling bus passes online" />
                    <p>If you want parents to buy bus passes and tickets from you, make it easy for them to buy them online. Having parents call your office and your team having to fight over the card machine no longer makes sense. This also allows parents to buy passes at a time convenient to them without having to wait for an operator to be available. Not only does this mean you save time and money, but the net effect of being more convenient is an edge over competition that isn't as forward thinking.</p>

                    <h2>4. Automate scheduled payments</h2>
                    <p>Although upfront card payments are the best way to ensure you are being paid in full for your services, we understand that for many parents being able to spread payments can really help. If parents prefer to pay in instalments, use direct debit to minimise having to manually chase parents for payment.</p>
                    <p>Still using standing orders and manual bank transfers? Here's <Link className="link" to="/8-reasons-standing-orders-are-bad-for-business/">8 reasons why standing orders are bad for business</Link>.</p>

                    <h2>5. Go cashless</h2>
                    <p>Whilst it might seem straightforward to ask passengers to pay in cash for daily journeys on a school service, it can actually add hours of admin onto a working week. It needs to be regularly counted and deposited at a bank, and then of course there is scope for human error and for cash to go missing occasionally.</p>
                    <p>The global pandemic of course has accelerated the need to go cashless meaning there is less change available in households for use on school services. Going cashless would make it easier on everyone.</p>

                    <h2>6. Mobile ticketing</h2>
                    <img src={imgMobileTicket} alt="Mobile ticket for school transport" />
                    <p>Don’t print and post passes to parents. Not only do you have to pay printing and postage costs but it takes a serious amount of time. Instead, ensure your passes can be sent electronically to parents, which is quicker and less costly.</p>
                    <p>Instead of a process that takes days and even weeks, it's one that is now reduced to minutes and seconds - and it doesn't even involve your own time.</p>

                    <h2>7. Scrap replacement passes</h2>
                    <p>Although many operators think they cover their costs by charging replacement pass fees (and some claim to make a profit), overall it's a waste of everyone's time. Even if you manage to cover the time spent with a fee, what you won't get back is the time spent on this task when it would have been better used on something productive. Anecdotally we also know that replacement passes are a key source of pass fraud, so a £5 replacement charge could end up costing you a lot more in the long run.</p>
                    <p>Switching to a <Link className="link" to="/">mobile ticketing system</Link> means there are no replacement passes, as the ticket is available 24/7 on any device and can also be printed at home.</p>

                    <h2>8. Turn registers digital</h2>
                    <p>Avoid any manual system to keep track of who is on your bus. Paper registers are slow, impact boarding throughput and end up causing more admin than they're worth.</p>
                    <p>You've then got another data entry task on your hands if you want to make the data actually useful as well as the GDPR issues around data retention to be mindful of (another admin task).</p>
                    <p>A simple mobile ticketing system would make this process quicker and easier.</p>

                    <h2>9. Fast and direct communication</h2>
                    <p>Make sure you have a quick automated system to get any critical message out direct to parents. Without it, your team can expect phone calls and emails from parents when ever there is an issue - which is exactly the time your team needs to be free.</p>
                    <p>If you use other platforms, such as Facebook or Twitter, be mindful that not everyone will have access and this is an indirect form of communication.</p>
                    <p>SMS texts are a great way to get important and time sensitive information out to parents. By improving the level of outgoing communication it can in turn reduce admin involved fielding calls and emails.</p>

                    <h2>10. Streamline renewals</h2>
                    <img src={imgBusPassRenewal} alt="Easy bus pass renewals" />
                    <p>For many operators, a big part of the job is actually dealing with renewals. For example, in high school that might be a current year 7 student, going into year 8, then into 9 and so on. The only new customers are generally the first year users, with the rest already customers who are progressing into their next year at school.</p>
                    <p>So if you could improve your process of handling renewals, you'd actually be improving up to 80% of the total job. Any efficiency you can make in this area compounds.</p>
                    <p>We recognised this problem at <Link className="link" to="/">ShuttleID</Link> and developed a system to handle renewals specifically. Just imagine if you could bulk select customers, and ask them to renew for next year or term, in a process that now takes seconds. Then imagine that just minutes later parents have already started to renew, with payment already made, and the pass already in their hands - and it required no intervention from your team. <strong>Huge admin savings</strong>.</p>

                    <h2>"The best thing we've done in years"</h2>
                    <p>If this article has struck a chord with you, you might be interested to learn a bit more about ShuttleID, a simple mobile ticketing system for home-to-school travel.</p>
                    <p>The system typically <strong>saves transport operators hundreds of hours in admin</strong> every year and the feedback we hear most from customers is the regret they didn't do something like this sooner. Here's how we can help:</p>
                    <ul style={{ width: 'auto' }}>
                      <li><strong>No more postal application forms.</strong> On our <Link className="link" to="/complete-package/">Complete Package</Link>, parents complete the passenger details securely online and receive their pass instantly.</li>
                      <li><strong>Photo uploads.</strong> In ShuttleID, parents upload a passenger photo securely for you to have access to in the back office in the event of an incident. No more cropping, resizing or scanning for you.</li>
                      <li><strong>Sell tickets online.</strong> Take payment (card and direct debit) automatically online using our Complete package. Just list the tickets for sale and parents snap them up when it's convenient for them.</li>
                      <li><strong>Automate scheduled payments.</strong> Our system gives you flexibility to offer instalments via direct debit. This way offers major differences to standing orders, giving you total control and a way to void passes if the payments ever stop.</li>
                      <li><strong>Go cashless.</strong> Going cashless takes the pressure of parents to find the correct change every week but also saves you all the headaches that come with having to handle cash. Read our <Link className="link" to="/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless/">case study of how we helped Applegates Coaches go cashless</Link>.</li>
                      <li><strong>Mobile ticketing.</strong> Our system gives customers what they want - their bus pass instantly and available to use on their phone.</li>
                      <li><strong>Scrap replacement passes.</strong> With digital passes available to customers 24/7, replacement passes are no longer a concept, and no longer a job you have to deal with!</li>
                      <li><strong>Turn registers digital.</strong> With each pass scanned at boarding, you automatically get a register of passengers for every journey which has proved invaluable during the pandemic but always a key feature for safeguarding purposes.</li>
                      <li><strong>Fast and direct communication.</strong> Our SMS service alerts allow you to select a service and blast out a message in seconds, keeping parents in the know and preventing the flood of phone calls into the office.</li>
                      <li><strong>Streamline renewals.</strong> Our renewal system significantly reduces up to 80% of the admin involved with taking payment and issuing passes, whilst also making it super convenient for parents.</li>
                    </ul>

                    <p>If you think it’s time to review the hidden cost of admin in your business, <Link to="/#contact">get in touch</Link> today. It can take as little as 24 hours to get set up and it’s available to operators of all sizes.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID, a cost effective cashless ticketing system designed to make managing home to school services simple.</p>
                        <p style={{ marginBottom: 10 }}>During a 14 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home to school first hand, leading to the launch of ShuttleID.</p>
                        <p style={{ margin: 0 }}>Email: <a href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage